import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Box, ListItem, Typography, List } from '@material-ui/core';

import { StaticImage } from "gatsby-plugin-image"

import Download from "../components/download"

import { Container, Row, Col, Card } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Admissiondetails = () => (
  <Layout>
    <Seo title="Admission Details" description="Admission details for 3 years and 5 years LLB" />
    <ThemeProvider theme={theme}>
<Box style={{ position:'relative', bottom:40 }}>
<Container>
    <Row>
        <Col xs={12} lg={8} className="bg-white">
        <div className="p-3 list-icon mt-4">
           <Typography variant="h4" style={{ color:'#453260' }}>ADMISSION PROCEDURE</Typography>
           <div className="text-center pt-3 pb-3">
           <StaticImage
      src="../images/admiss.jpg"
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="ADMISSION PROCEDURE"
      class="border p-2 shadow-sm bg-light"
    />
        </div>
           <List className="ps-2">
               <ListItem>The list of selected candidates on the admission date will be published on the college notice board.</ListItem>
           <ListItem>Admission will be denied if the candidate does not join the college within the stipListated time.</ListItem>
           <ListItem>The candidate is expected to meet the principal on the appointed date and time, Accompanied by parent</ListItem>
           </List>

    <Typography variant="h6" style={{ color:'#453260' }}>Eligibility for admission to 3 Years LLB.,Course</Typography>
    <List className="ps-2">
        <ListItem>
        A candidate for admission to the 3 years LLB.Course shoListd have:
a)Passed BA,B.Com.,B.SC.,B.BA,B.B.M.,or any other equivalent Degree recognised by the University,securing an aggregate not less than 45% of the total marks for general candidates and 40% for SC/ST candidates.
        </ListItem>
    </List>

    <Typography variant="h6" style={{ color:'#453260' }}>Documents to be submitted at the time of admission:</Typography>
    <List className="ps-2">
    <ListItem> 5 passport size photographs</ListItem>
    <ListItem> Original certificates</ListItem>
    <ListItem> Degree Marks card</ListItem>
    <ListItem> Provisional degree Certificate or Convocation</ListItem>
    <ListItem> P.U.C./H.S.C./ P.D.C./intermediate marks card</ListItem>
    <ListItem> S.S.L.C. or equivalent marks card</ListItem>
    <ListItem> Transfer Certificates from the college last attended</ListItem>
    <ListItem> Migration certificate</ListItem>
    <ListItem> Caste Certificate</ListItem>
    </List>

    <Typography variant="h6" style={{ color:'#453260' }}>Additional documents to be produced by the foreign candidates</Typography>
    <List className="ps-2">
    <ListItem>Provisional eligibility certificate issued by Bangalore University / Karnataka State Law University, Hubli.</ListItem>
    <ListItem>Valid student visa</ListItem>
    <ListItem>AIDS clearance certificate</ListItem>
    <ListItem>Passport copy</ListItem>
    <ListItem>Residential permit</ListItem>
    </List>

    <Typography variant="h6" style={{ color:'#453260' }}>Additional documents to be produced by N.R.I candidates</Typography>
    <List className="ps-2">
    <ListItem>Provisional eligibility certificate issued by Bangalore University / Karnataka State Law University, Hubli.</ListItem>
    <ListItem>AIDS clearance certificate</ListItem>
    <ListItem>Copy of passport</ListItem>
    <ListItem>The candidates selected will be enrolled on payment of fee, the fee once paid will not be refunded The admission is subjected to Bangalore university approval/ Karnataka state law university, hubi.</ListItem>
    </List>

    <Typography variant="h6" style={{ color:'#453260' }}>Eligibility for admission to 5 Years LLB.,Course</Typography>
    <List className="ps-2">
    <ListItem>Minimum marks for admission to 1st Year LL.B. is 45%</ListItem>
    <ListItem>SC/ST and BT 40% in aggregate.</ListItem>
    </List>

    <Typography variant="h6" style={{ color:'#453260' }}>In the following examinations :</Typography>
    <List className="ps-2">
    <ListItem>HSC - Higher Secondary School Certificate XII Standard</ListItem>
    <ListItem>PUC - Pre-University Course-P.D.C.</ListItem>
    <ListItem>CBSE - Central Board of Secondary Education-XII Standard</ListItem>
    </List>

    <Typography variant="h6" style={{ color:'#453260' }}>Interview :</Typography>
    <List className="ps-2">
    <ListItem>The interview will assess the student's personally and education / career interest</ListItem>

    </List>

    <Typography variant="h6" style={{ color:'#453260' }}>Selection :</Typography>
    <List className="ps-2">
    <ListItem>Admission will be decided on the basis of the cumListative performance of students.</ListItem>

    </List>

    <Typography variant="h6" style={{ color:'#453260' }}>Note :</Typography>
    <List className="ps-2">
    <ListItem>The list of candidates who have gained provisional admission will be displayed on the Dr.B.R.Ambedkar College of Law campus notice board.</ListItem>

    </List>
           
        </div>
        </Col>
        <Col xs={12} lg={4} className="bg-white">
        <Card className="shadow mb-2 bg-light mt-5">
  <Card.Body>
<Download/>
</Card.Body>
</Card>
 
</Col>

    </Row>
</Container>
</Box>

</ThemeProvider>
  </Layout>
)

export default Admissiondetails
